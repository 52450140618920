import { Controller } from '@hotwired/stimulus'

export default class TabsController extends Controller {
  static targets = ["tab", "tabPanel"]
  static classes = ["hidden", "active"]

  initialize() {
    this.showTab()
  }

  change(event) {
    this.currentId = event.params.id
    this.showTab()
  }

  showTab() {
    this.tabPanelTargets.forEach((el) => {
      if (el.dataset.tabPanelId == this.currentId) {
        el.classList.remove(this.hiddenClass)
      } else {
        el.classList.add(this.hiddenClass)
      }
    })

    this.tabTargets.forEach((el) => {
      if (el.dataset.tabsIdParam == this.currentId) {
        el.classList.add(this.activeClass)
      } else {
        el.classList.remove(this.activeClass)
      }
    })
  }

  get currentId() {
    return this.data.get("currentId")
  }

  set currentId(value) {
    this.data.set("currentId", value)
    this.showTab()
  }
}
