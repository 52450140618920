import _ from "./shared/airbrake"

// Turbo
import "@hotwired/turbo-rails"

// Stimulus
import { Application } from "@hotwired/stimulus"
import ScrollToController from "./controllers/scroll_to_controller";
import SubmittableController from "./controllers/submittable_controller";
import { definitions } from "stimulus:./marketing/controllers"
const app = Application.start()
app.register("scroll-to", ScrollToController)
app.register("submittable", SubmittableController)
app.load(definitions)

// This has been copied from shared/base.js
function onDocumentLoaded () {
  const linksWithParams = document.querySelectorAll("[rel~='keep-params']");

  linksWithParams.forEach(link => {
    link.addEventListener("click", function(e){
      e.preventDefault();

      const href = link.getAttribute("href");
      const targetUrl = new URL(href, window.location);
      const newParams = Object.fromEntries(targetUrl.searchParams.entries());

      // Merge new params into current URL params and go
      const url = new URL(window.location);
      Object.keys(newParams).forEach(key => url.searchParams.set(key, newParams[key]));
      window.location.href = url;
    });
  });
}

if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", onDocumentLoaded);
} else {
  onDocumentLoaded();
}
