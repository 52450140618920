import { Controller } from '@hotwired/stimulus'
import * as API from "../../lib/API"
let debounce = require('lodash/debounce')

export default class CitySearchController extends Controller {
  static targets = ["cityInput", "resultsToShow"]
  static classes = ["resultItem"]

  initialize() {
    this.fetchCities = debounce(this.fetchCities, 200).bind(this)
    this.results = []
  }

  displayResults() {
    const resultItemClasses = this.resultItemClasses.join(" ");
    this.resultsToShowTarget.innerHTML = this.results.map((city) => {
      return `<a href="#" data-city="${city.id}" data-action="click->city-search#handleClick" class="${resultItemClasses}">${city.description}</a>`
    }).join("")
    this.resultsToShowTarget.classList.remove("hidden")
  }

  clearInput() {
    this.cityInputTarget.value = ""
  }

  fetchCities = async (cityInput) => {
    const response = await API.get("/data/geo/cities/", {q: cityInput.target.value});
    this.results = response
    this.displayResults()
    if(this.cityInputTarget.value === "") {
      this.resultsToShowTarget.classList.add("hidden")
    }
  }

  handleKeyDown() {
    if(event.keyCode === 13) {
      event.preventDefault()
      this.navigateToCity(this.results[0].id)
    }
  }

  handleClick(event) {
    event.preventDefault()
    this.navigateToCity(event.target.dataset.city)
  }

  navigateToCity(city) {
    const url = new URL(window.location);
    url.searchParams.set("city", city);
    window.location.href = url
  }
}
