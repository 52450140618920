import { Controller } from '@hotwired/stimulus';

export default class SubmittableController extends Controller {
  static targets = ["form"];

  connect() {
    const form = this.formTarget;
    form.classList.add("group");
  }

  // TODO: Update this controller or make a new one
  // Submitting a form via <enter> should also trigger these actions
  // Right now it only works if you click the button

  submit() {
    const form = this.formTarget;
    form.dataset.loading = true

    form.querySelectorAll('[type="submit"]').forEach(function(el) {
      el.disabled = true;
      el.classList.add('disabled');
      el.blur();
    });

    form.querySelectorAll('[data-loading-html]').forEach(function(el) {
      el.innerHTML = el.dataset.loadingHtml;
    });

    form.querySelectorAll('[data-loading-class]').forEach(function(el) {
      el.classList.add(el.dataset.loadingClass);
    });

    form.submit();
  }
}
