import Dropdown from '@stimulus-components/dropdown'

export default class extends Dropdown {
  static classes = ["open"]

  toggle(event) {
    super.toggle(event)
    if (this.openClasses.length > 0) {
      this.element.classList.toggle(...this.openClasses)
    }
  }

  hide(event) {
    if (!this.element.contains(event.target) && !this.menuTarget.classList.contains('hidden')) {
      this.leave()
      if (this.openClasses.length > 0) {
        this.element.classList.remove(...this.openClasses)
      }
    }
  }
}
